<template>
  <el-card class="history-card">
    <el-form class="history-form">
      <!-- Linha 1: Seleção de dispositivo e datas -->
      <div class="form-row">
        <!-- Seleção de dispositivo -->
        <el-form-item class="device-selector">
          <el-select 
            v-model="formData.deviceId" 
            :value-key="'id'" 
            filterable 
            :placeholder="$t('device.device')" 
            size="default" 
            :no-data-text="$t('NO_DATA_TEXT')" 
            :no-match-text="$t('NO_MATCH_TEXT')"
          >
            <el-option
              v-for="item in store.state.devices.deviceList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            />
          </el-select>
        </el-form-item>
        
        <!-- Botões compactos-->
        <div class="compact-buttons">
          <el-button-group size="small">
            <el-button 
              type="primary" 
              :disabled="!formData.deviceId || !formData.date[0] || !formData.date[1]" 
              @mouseleave="hideTip" 
              @mouseenter.stop="showTip($event, $t('device.routes'))" 
              @click="loadRoute()"
            >
              <i class="fas fa-route"></i>
            </el-button>
            
            <el-button 
              type="primary" 
              @mouseleave="hideTip" 
              @mouseenter.stop="showTip($event, $t('device.graphic'))" 
              :disabled="!formData.deviceId || !formData.date[0] || !formData.date[1]" 
              @click="loadGraph()"
            >
              <i class="fas fa-chart-bar"></i>
            </el-button>
            
            <el-button 
              type="info" 
              :disabled="!formData.deviceId || !formData.date[0] || !formData.date[1]" 
              @click="loadRoute(false, true)"
            >
              <i class="fas fa-file-export"></i>
            </el-button>
            
            <el-button 
              type="success" 
              :loading="isLoading" 
              :disabled="!formData.deviceId || !formData.date[0] || !formData.date[1]" 
              @click="handleGeneratePDF"
            >
              <i class="fas fa-file-pdf"></i>
            </el-button>
          </el-button-group>
        </div>
      </div>
      
      <!-- Linha 2: Datas e switch -->
      <div class="form-row dates-row">
        <!-- Datas compactas -->
        <div class="date-container">
          <el-input 
            v-model="formData.date[0]" 
            type="datetime-local"
            :placeholder="$t('startDate')"
            size="small"
          />
          
          <div class="date-separator">
            <i class="fas fa-arrow-right"></i>
          </div>
          
          <el-input 
            v-model="formData.date[1]" 
            type="datetime-local"
            :placeholder="$t('endDate')"
            size="small"
          />
        </div>
        
        <div class="marker-container">
          <el-tooltip :content="$t('report.showMarkers')" placement="top">
            <el-switch 
              v-model="showRouteMarkers" 
              class="marker-switch"
              size="small"
            >
              <template #default>
                <span class="switch-label">
                  <i class="fas fa-map-marker-alt"></i>
                </span>
              </template>
            </el-switch>
          </el-tooltip>
        </div>
      </div>
    </el-form>
  
    <!-- Conteúdo do histórico -->
    <el-card class="history-content" shadow="never">
      <!-- Estado de carregamento -->
      <template v-if="loading">
        <div class="loading-state">
          <i class="fas fa-spinner fa-spin"></i>
          <span>{{$t('LOADING')}}</span>
        </div>
      </template>
      
      <!-- Estado vazio -->
      <template v-else-if="routePoints.length===0">
        <div class="empty-state">
          <i class="fas fa-route"></i>
          <span>{{$t('route.empty')}}</span>
        </div>
      </template>
      
      <!-- Exibição da rota -->
      <template v-else>
        <div class="route-timeline">
          <!-- Ponto inicial -->
          <div class="timeline-point timeline-start">
            <div class="timeline-marker">
              <div class="timeline-line timeline-line-start"></div>
              <div class="timeline-icon">
                <i class="fas fa-flag"></i>
              </div>
              <div class="timeline-date">
                {{ new Date(routePoints[0].fixTime).toLocaleDateString() }}<br>
                {{ new Date(routePoints[0].fixTime).toLocaleTimeString() }}
              </div>
            </div>
            
            <div class="timeline-content">
              <div class="address">{{ routePoints[0].address }}</div>
              
              <div class="point-details">
                <div class="speed">
                  <i class="fas fa-tachometer-alt"></i>
                  {{$t('units.' + store.getters['server/getAttribute']('speedUnit', 'speedUnit'), { speed: routePoints[0].speed })}}
                </div>
                
                <div class="attributes">
                  <!-- Ignição -->
                  <div 
                    v-if="routePoints[0].attributes && routePoints[0].attributes.ignition !== undefined" 
                    class="attribute" 
                    :data-title="$t('device.ignition') + ': ' + (routePoints[0].attributes.ignition ? $t('yes') : $t('no'))"
                  >
                    <i :class="{
                      'fas fa-key': true,
                      'text-success': routePoints[0].attributes.ignition === true,
                      'text-danger': routePoints[0].attributes.ignition === false,
                      'text-muted': routePoints[0].attributes.ignition === undefined
                    }"></i>
                  </div>
                  
                  <!-- Bloqueio -->
                  <div 
                    v-if="routePoints[0].attributes && routePoints[0].attributes.blocked !== undefined" 
                    class="attribute"
                    :data-title="$t('device.blocked') + ': ' + (routePoints[0].attributes.blocked ? $t('yes') : $t('no'))"
                  >
                    <i :class="{
                      'fas fa-lock': true,
                      'text-success': routePoints[0].attributes.blocked === false,
                      'text-danger': routePoints[0].attributes.blocked === true,
                      'text-muted': routePoints[0].attributes.blocked === undefined
                    }"></i>
                  </div>
                  
                  <!-- Movimento -->
                  <div 
                    v-if="routePoints[0].attributes && routePoints[0].attributes.motion !== undefined" 
                    class="attribute"
                    :data-title="$t('device.motion') + ': ' + (routePoints[0].attributes.motion ? $t('yes') : $t('no'))"
                  >
                    <i :class="{
                      'fas fa-car': routePoints[0].attributes.motion === true,
                      'fas fa-parking': routePoints[0].attributes.motion === false,
                      'text-success': routePoints[0].attributes.motion === true,
                      'text-danger': routePoints[0].attributes.motion === false,
                      'text-muted': routePoints[0].attributes.motion === undefined
                    }"></i>
                  </div>
                  
                  <!-- Energia -->
                  <div 
                    v-if="routePoints[0].attributes && routePoints[0].attributes.power !== undefined" 
                    class="attribute"
                    :data-title="$t('device.power')"
                  >
                    <i class="fas fa-car-battery" :class="{
                      'text-success': routePoints[0].attributes.power > 0,
                      'text-muted': routePoints[0].attributes.power <= 0
                    }"></i>
                    <span class="attribute-value">
                      {{ (parseFloat(routePoints[0].attributes.power)).toFixed(1) }} V
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          
          <!-- Pontos intermediários -->
          <template v-for="(p, k) in routePoints" :key="p.id">
            <div v-if="k > 0 && k < routePoints.length - 1" class="timeline-point timeline-middle">
              <div class="timeline-marker">
                <div class="timeline-line"></div>
                <div class="timeline-dot"></div>
                <div class="timeline-date">
                  {{ new Date(p.fixTime).toLocaleDateString() }}<br>
                  {{ new Date(p.fixTime).toLocaleTimeString() }}
                </div>
              </div>
              
              <div class="timeline-content">
                <div class="address">
                  <a target="_blank" class="location-link" :href="'https://maps.google.com/?q=' + p.latitude + ',' + p.longitude">
                    {{ p.address || p.latitude + ',' + p.longitude }} 
                    <i class="fas fa-external-link-alt"></i>
                  </a>
                </div>
                
                <div class="point-details">
                  <div class="speed">
                    <i class="fas fa-tachometer-alt"></i>
                    {{$t('units.' + store.getters['server/getAttribute']('speedUnit', 'speedUnit'), { speed: p.speed })}}
                  </div>
                  
                  <div class="attributes">
                    <!-- Ignição -->
                    <div v-if="p.attributes && p.attributes.ignition !== undefined" class="attribute">
                      <i :class="{
                        'fas fa-key': true,
                        'text-success': p.attributes.ignition === true,
                        'text-danger': p.attributes.ignition === false,
                        'text-muted': p.attributes.ignition === undefined
                      }"></i>
                    </div>
                    
                    <!-- Bloqueio -->
                    <div v-if="p.attributes && p.attributes.blocked !== undefined" class="attribute">
                      <i :class="{
                        'fas fa-lock': true,
                        'text-success': p.attributes.blocked === false,
                        'text-danger': p.attributes.blocked === true,
                        'text-muted': p.attributes.blocked === undefined
                      }"></i>
                    </div>
                    
                    <!-- Movimento -->
                    <div v-if="p.attributes && p.attributes.motion !== undefined" class="attribute">
                      <i :class="{
                        'fas fa-car': p.attributes.motion === true,
                        'fas fa-parking': p.attributes.motion === false,
                        'text-success': p.attributes.motion === true,
                        'text-danger': p.attributes.motion === false,
                        'text-muted': p.attributes.motion === undefined
                      }"></i>
                    </div>
                    
                    <!-- Energia -->
                    <div v-if="p.attributes && p.attributes.power !== undefined" class="attribute">
                      <i class="fas fa-car-battery" :class="{
                        'text-success': p.attributes.power > 0,
                        'text-muted': p.attributes.power <= 0
                      }"></i>
                      <span class="attribute-value">
                        {{ (parseFloat(p.attributes.power)).toFixed(1) }} V
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </template>
          
          <!-- Ponto final -->
          <div class="timeline-point timeline-end">
            <div class="timeline-marker">
              <div class="timeline-line timeline-line-end"></div>
              <div class="timeline-icon">
                <i class="fas fa-flag-checkered"></i>
              </div>
              <div class="timeline-date">
                {{ new Date(routePoints[routePoints.length-1].fixTime).toLocaleDateString() }}<br>
                {{ new Date(routePoints[routePoints.length-1].fixTime).toLocaleTimeString() }}
              </div>
            </div>
            
            <div class="timeline-content">
              <div class="address">{{ routePoints[routePoints.length-1].address }}</div>
              
              <div class="point-details">
                <div class="speed">
                  <i class="fas fa-tachometer-alt"></i>
                  {{$t('units.' + store.getters['server/getAttribute']('speedUnit', 'speedUnit'), { speed: routePoints[routePoints.length-1].speed })}}
                </div>
                
                <div class="attributes">
                  <!-- Ignição -->
                  <div v-if="routePoints[routePoints.length-1].attributes && routePoints[routePoints.length-1].attributes.ignition !== undefined" class="attribute">
                    <i :class="{
                      'fas fa-key': true,
                      'text-success': routePoints[routePoints.length-1].attributes.ignition === true,
                      'text-danger': routePoints[routePoints.length-1].attributes.ignition === false,
                      'text-muted': routePoints[routePoints.length-1].attributes.ignition === undefined
                    }"></i>
                  </div>
                  
                  <!-- Bloqueio -->
                  <div v-if="routePoints[routePoints.length-1].attributes && routePoints[routePoints.length-1].attributes.blocked !== undefined" class="attribute">
                    <i :class="{
                      'fas fa-lock': true,
                      'text-success': routePoints[routePoints.length-1].attributes.blocked === false,
                      'text-danger': routePoints[routePoints.length-1].attributes.blocked === true,
                      'text-muted': routePoints[routePoints.length-1].attributes.blocked === undefined
                    }"></i>
                  </div>
                  
                  <!-- Movimento -->
                  <div v-if="routePoints[routePoints.length-1].attributes && routePoints[routePoints.length-1].attributes.motion !== undefined" class="attribute">
                    <i :class="{
                      'fas fa-car': routePoints[routePoints.length-1].attributes.motion === true,
                      'fas fa-parking': routePoints[routePoints.length-1].attributes.motion === false,
                      'text-success': routePoints[routePoints.length-1].attributes.motion === true,
                      'text-danger': routePoints[routePoints.length-1].attributes.motion === false,
                      'text-muted': routePoints[routePoints.length-1].attributes.motion === undefined
                    }"></i>
                  </div>
                  
                  <!-- Energia -->
                  <div v-if="routePoints[routePoints.length-1].attributes && routePoints[routePoints.length-1].attributes.power !== undefined" class="attribute">
                    <i class="fas fa-car-battery" :class="{
                      'text-success': routePoints[routePoints.length-1].attributes.power > 0,
                      'text-muted': routePoints[routePoints.length-1].attributes.power <= 0
                    }"></i>
                    <span class="attribute-value">
                      {{ (parseFloat(routePoints[routePoints.length-1].attributes.power)).toFixed(1) }} V
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
    </el-card>
  </el-card>
</template>

<script setup>
import 'element-plus/es/components/button/style/css'
import 'element-plus/es/components/icon/style/css'
import 'element-plus/es/components/tooltip/style/css'
import 'element-plus/es/components/form/style/css'
import 'element-plus/es/components/form-item/style/css'
import 'element-plus/es/components/select/style/css'
import 'element-plus/es/components/option/style/css'
import 'element-plus/es/components/switch/style/css'
import 'element-plus/es/components/input/style/css'

import { ElButton, ElForm, ElSelect, ElOption, ElFormItem, ElSwitch, ElInput, ElMessage } from "element-plus";
import 'element-plus/es/components/message/style/css';
import { ref, inject, onMounted, watch, onBeforeUnmount, nextTick } from 'vue';
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';
import { saveAs } from "file-saver";

// import L from 'leaflet';
import { faLock, faLockOpen, faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import { library } from '@fortawesome/fontawesome-svg-core';

// Agregar iconos a la librería
library.add(faLock, faLockOpen, faCircleNotch);
import html2pdf from "html2pdf.js";

import dayjs from 'dayjs';
const showGraphicsRef = inject("show-graphics");
const loading = ref(false);
const date1 = new Date();
const date2 = new Date();
date1.setHours(0);
date1.setMinutes(0);
const showRouteMarkers = inject("showRouteMarkers");

const formData = ref({ deviceId: '', date: [date1, date2] });
const store = useStore();
const route = useRoute();
const routePoints = ref([]);

// Definición de la función showTip
const showTip = (evt, text) => {
  window.$showTip(evt, text); // Asegúrate de que esta función esté disponible
};

// Definición de la función hideTip
const hideTip = (evt) => {
  window.$hideTip(evt); // Asegúrate de que esta función esté disponible
};

onMounted(() => {
  if (route.query.deviceId) {
    formData.value.deviceId = parseInt(route.query.deviceId);
    
    // Si viene 'from' y 'to' en la URL, usar esos valores para formData.date
    if (route.query.from && route.query.to) {
      console.log('Parámetros originales de URL:', route.query.from, route.query.to);
      
      try {
        // La fecha viene en formato YYYY-MM-DDThh:mm:ss (local)
        const fromStr = route.query.from;
        const toStr = route.query.to;
        
        // Para input datetime-local necesitamos YYYY-MM-DDThh:mm
        // Simplemente tomamos los primeros 16 caracteres
        formData.value.date[0] = fromStr.slice(0, 16);
        formData.value.date[1] = toStr.slice(0, 16);
        
        console.log('Fechas asignadas a inputs:', formData.value.date);
      } catch (error) {
        console.error('Error procesando fechas:', error);
      }
    }
    
    loadRoute();
  }
});

onBeforeUnmount(() => {
  // Puedes agregar lógica de limpieza aquí si es necesario
});

watch(() => route.query, (newQuery) => {
  if (newQuery.deviceId) {
    formData.value.deviceId = parseInt(newQuery.deviceId);
    
    // Si viene 'from' y 'to' en la URL, usar esos valores para formData.date
    if (newQuery.from && newQuery.to) {
      console.log('Parámetros actualizados en URL:', newQuery.from, newQuery.to);
      
      try {
        // La fecha viene en formato YYYY-MM-DDThh:mm:ss (local)
        const fromStr = newQuery.from;
        const toStr = newQuery.to;
        
        // Para input datetime-local necesitamos YYYY-MM-DDThh:mm
        // Simplemente tomamos los primeros 16 caracteres
        formData.value.date[0] = fromStr.slice(0, 16);
        formData.value.date[1] = toStr.slice(0, 16);
        
        console.log('Fechas actualizadas a inputs:', formData.value.date);
      } catch (error) {
        console.error('Error procesando fechas actualizadas:', error);
      }
    }
    
    loadRoute();
  }
}, { deep: true });

const updateRoute = inject('updateRoute');

const loadGraph = () => {
  if (routePoints.value.length === 0) {
    loadRoute(true);
  } else {
    showGraphicsRef.value.showGraphic(routePoints.value);
  }
};

const loadRoute = async (g = false, e = false) => {
  loading.value = true;
  
  try {
    // Verificar si estamos usando los parámetros directamente desde la URL
    let startDate, endDate;
    
    if (route.query.from && route.query.to) {
      console.log('Usando fechas desde params de URL para consulta');
      // En la URL ya están en formato YYYY-MM-DDThh:mm:ss
      startDate = route.query.from;
      endDate = route.query.to;
    } else if (formData.value.date[0] && formData.value.date[1]) {
      console.log('Usando fechas de formulario para consulta:', formData.value.date);
      // De los inputs necesitamos completarlos a formato YYYY-MM-DDThh:mm:ss
      startDate = formData.value.date[0] + ':00';
      endDate = formData.value.date[1] + ':00';
    } else {
      // Fallback para asegurar que siempre hay fechas
      console.log('Usando fechas predeterminadas');
      const now = new Date();
      const yesterday = new Date(now);
      yesterday.setDate(yesterday.getDate() - 1);
      
      const formatDate = (date) => {
        const pad = (num) => num.toString().padStart(2, '0');
        return `${date.getFullYear()}-${pad(date.getMonth() + 1)}-${pad(date.getDate())}T${pad(date.getHours())}:${pad(date.getMinutes())}:${pad(date.getSeconds())}`;
      };
      
      startDate = formatDate(yesterday);
      endDate = formatDate(now);
    }
    
    const params = {
      deviceId: formData.value.deviceId,
      startDate: startDate,
      endDate: endDate,
      isExport: e
    };
    
    console.log('Parámetros de consulta:', params);
    
    const result = await store.dispatch('routes/loadRoute', params);
    
    if (e) {
      // Lógica para exportar a Excel o PDF
      if (result['headers']['content-type'] === 'application/pdf') {
        saveAs(new Blob([result.data], { type: 'application/pdf' }), 'resume.pdf');
      } else {
        saveAs(new Blob([result.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' }), 'resume.xlsx');
      }
    } else {
      // Atualizar os dados da rota na interface
      routePoints.value = result.data;
      
      // Atualizar a rota no mapa
      updateRoute(result.points);
      
      nextTick(() => {
        hideDevices(formData.value.deviceId);
      });

      // Carregar o gráfico se necessário
      if (g && result.data.length > 0) {
        loadGraph();
      }
      
      // Actualizar URL con los parámetros correctos si vienen de un segmento
      if (route.query.from && route.query.to) {
        console.log('Datos cargados con éxito usando fechas:', startDate, '-', endDate);
      }
    }
  } catch (error) {
    console.error("Erro ao carregar rota:", error);
    ElMessage.error('Erro ao carregar rota');
  } finally {
    loading.value = false;
  }
};

// Función para ocultar dispositivos
const hideDevices = (deviceId = 0) => {
  store.dispatch("devices/setDeviceFilter", deviceId);
};

const generatePDF = async () => {
  try {
    if (routePoints.value.length === 0) {
      ElMessage.error('Não há dados de rota disponíveis');
      return;
    }

    // Buscar informações do dispositivo
    const deviceId = formData.value.deviceId;
    const deviceInfo = await fetchDeviceById(deviceId);
    
    // Formatar datas para exibição
    const startPoint = routePoints.value[0];
    const endPoint = routePoints.value[routePoints.value.length - 1];
    const startDate = dayjs(startPoint.deviceTime).format('DD/MM/YYYY HH:mm');
    const endDate = dayjs(endPoint.deviceTime).format('DD/MM/YYYY HH:mm');
    
    // Cálculo de métricas
    const totalDistance = routePoints.value.reduce(
      (sum, point) => sum + (point.attributes?.distance || 0), 0
    ) / 1000; // em km
    
    const speeds = routePoints.value.map(p => p.speed * 1.852);
    const maxSpeed = Math.max(...speeds, 0);
    const avgSpeed = speeds.length > 0 ? 
      speeds.reduce((a, b) => a + b, 0) / speeds.length : 0;
    
    // Criar HTML do relatório diretamente - versão moderna com ícones
    const htmlContent = `
    <div class="pdf-container" style="font-family: 'Helvetica', 'Arial', sans-serif; max-width: 800px; margin: 0 auto; padding: 20px; background-color: white; color: #333;">
      <!-- Cabeçalho com gradiente -->
      <div class="pdf-header" style="display: flex; align-items: center; padding: 15px; border-radius: 8px; margin-bottom: 20px; background: linear-gradient(135deg, #00539c, #0077cc); box-shadow: 0 2px 6px rgba(0,0,0,0.1);">
        <div class="logo" style="width: 80px; padding-right: 15px;">
          <img src="/img/logoWhite.png" alt="Logo" style="max-width: 100%;" />
        </div>
        <div class="header-title" style="flex: 1; border-left: 2px solid rgba(255,255,255,0.3); padding-left: 15px;">
          <h1 style="font-size: 20px; margin: 0; color: white; text-shadow: 0 1px 2px rgba(0,0,0,0.2);">Relatório de Histórico de Rota</h1>
          <p style="margin: 5px 0 0; font-size: 12px; color: rgba(255,255,255,0.8);">
            <i class="fas fa-calendar-alt" style="margin-right: 5px;"></i> Gerado em: ${dayjs().format('DD/MM/YYYY HH:mm')}
          </p>
        </div>
      </div>
      
      <!-- Informações do dispositivo com card estilizado -->
      <div class="device-info" style="margin-top: 15px; background-color: white; border-radius: 8px; padding: 15px; box-shadow: 0 2px 4px rgba(0,0,0,0.05); border: 1px solid #eaeaea;">
        <h2 style="font-size: 16px; margin: 0 0 15px 0; color: #00539c; display: flex; align-items: center;">
          <i class="fas fa-car" style="margin-right: 8px; color: #00539c;"></i> Informações do Dispositivo
        </h2>
        
        <div class="info-section" style="display: flex; flex-wrap: wrap; justify-content: space-between;">
          <div class="info-col" style="width: 48%;">
            <p style="margin: 8px 0; font-size: 12px; display: flex; align-items: center;">
              <i class="fas fa-tag" style="width: 16px; margin-right: 8px; color: #00539c;"></i>
              <span><strong>Nome:</strong> ${deviceInfo?.name || `Dispositivo ${deviceId}`}</span>
            </p>
            <p style="margin: 8px 0; font-size: 12px; display: flex; align-items: center;">
              <i class="fas fa-fingerprint" style="width: 16px; margin-right: 8px; color: #00539c;"></i>
              <span><strong>ID:</strong> ${deviceId}</span>
            </p>
            <p style="margin: 8px 0; font-size: 12px; display: flex; align-items: center;">
              <i class="fas fa-id-card" style="width: 16px; margin-right: 8px; color: #00539c;"></i>
              <span><strong>Placa:</strong> ${deviceInfo?.attributes?.placa || 'N/A'}</span>
            </p>
          </div>
          <div class="info-col" style="width: 48%;">
            <p style="margin: 8px 0; font-size: 12px; display: flex; align-items: center;">
              <i class="fas fa-clock" style="width: 16px; margin-right: 8px; color: #00539c;"></i>
              <span><strong>Período:</strong> ${startDate} - ${endDate}</span>
            </p>
          </div>
        </div>
      </div>
      
      <!-- Resumo da rota com cards coloridos -->
      <div class="route-summary" style="margin-top: 15px; background-color: white; border-radius: 8px; padding: 15px; box-shadow: 0 2px 4px rgba(0,0,0,0.05); border: 1px solid #eaeaea;">
        <h2 style="font-size: 16px; margin: 0 0 15px 0; color: #00539c; display: flex; align-items: center;">
          <i class="fas fa-chart-pie" style="margin-right: 8px; color: #00539c;"></i> Resumo da Rota
        </h2>
        
        <div class="metrics-container" style="display: flex; flex-wrap: wrap; margin: -5px; justify-content: space-between;">
          <!-- Card de distância -->
          <div class="metric-card" style="flex-basis: calc(50% - 10px); margin: 5px; padding: 12px; border-radius: 6px; background: linear-gradient(to right, #00539c, #0077cc); color: white; box-shadow: 0 2px 5px rgba(0,0,0,0.1);">
            <div style="display: flex; align-items: center;">
              <i class="fas fa-route" style="font-size: 24px; margin-right: 10px; opacity: 0.8;"></i>
              <div>
                <div style="font-size: 10px; text-transform: uppercase; letter-spacing: 1px; opacity: 0.8;">Distância Total</div>
                <div style="font-size: 18px; font-weight: bold; margin-top: 3px;">${totalDistance.toFixed(2)} km</div>
              </div>
            </div>
          </div>
          
          <!-- Card de velocidade máxima -->
          <div class="metric-card" style="flex-basis: calc(50% - 10px); margin: 5px; padding: 12px; border-radius: 6px; background: linear-gradient(to right, #e74c3c, #ff7675); color: white; box-shadow: 0 2px 5px rgba(0,0,0,0.1);">
            <div style="display: flex; align-items: center;">
              <i class="fas fa-tachometer-alt" style="font-size: 24px; margin-right: 10px; opacity: 0.8;"></i>
              <div>
                <div style="font-size: 10px; text-transform: uppercase; letter-spacing: 1px; opacity: 0.8;">Velocidade Máxima</div>
                <div style="font-size: 18px; font-weight: bold; margin-top: 3px;">${maxSpeed.toFixed(1)} km/h</div>
              </div>
            </div>
          </div>
          
          <!-- Card de velocidade média -->
          <div class="metric-card" style="flex-basis: calc(50% - 10px); margin: 5px; padding: 12px; border-radius: 6px; background: linear-gradient(to right, #2ecc71, #55efc4); color: white; box-shadow: 0 2px 5px rgba(0,0,0,0.1);">
            <div style="display: flex; align-items: center;">
              <i class="fas fa-gauge-high" style="font-size: 24px; margin-right: 10px; opacity: 0.8;"></i>
              <div>
                <div style="font-size: 10px; text-transform: uppercase; letter-spacing: 1px; opacity: 0.8;">Velocidade Média</div>
                <div style="font-size: 18px; font-weight: bold; margin-top: 3px;">${avgSpeed.toFixed(1)} km/h</div>
              </div>
            </div>
          </div>
          
          <!-- Card de pontos -->
          <div class="metric-card" style="flex-basis: calc(50% - 10px); margin: 5px; padding: 12px; border-radius: 6px; background: linear-gradient(to right, #9b59b6, #a55eea); color: white; box-shadow: 0 2px 5px rgba(0,0,0,0.1);">
            <div style="display: flex; align-items: center;">
              <i class="fas fa-map-marker-alt" style="font-size: 24px; margin-right: 10px; opacity: 0.8;"></i>
              <div>
                <div style="font-size: 10px; text-transform: uppercase; letter-spacing: 1px; opacity: 0.8;">Pontos Analisados</div>
                <div style="font-size: 18px; font-weight: bold; margin-top: 3px;">${routePoints.value.length}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      
      <!-- Detalhes dos pontos com tabela moderna -->
      <div class="route-details" style="margin-top: 15px; background-color: white; border-radius: 8px; padding: 15px; box-shadow: 0 2px 4px rgba(0,0,0,0.05); border: 1px solid #eaeaea;">
        <h2 style="font-size: 16px; margin: 0 0 15px 0; color: #00539c; display: flex; align-items: center;">
          <i class="fas fa-list-ul" style="margin-right: 8px; color: #00539c;"></i> Detalhes dos Pontos
        </h2>
        
        ${routePoints.value.length > 50 ? 
          `<p style="font-style: italic; font-size: 10px; color: #666; text-align: center; margin: 10px 0; display: flex; align-items: center; justify-content: center;">
            <i class="fas fa-info-circle" style="margin-right: 5px; color: #00539c;"></i> 
            Exibindo 50 pontos de ${routePoints.value.length} para melhor visualização
          </p>` : 
          ''}
        
        <table class="points-table" style="width: 100%; border-collapse: collapse; margin-top: 10px; font-size: 10px; border-radius: 8px; overflow: hidden; box-shadow: 0 1px 3px rgba(0,0,0,0.1);">
          <thead>
            <tr>
              <th style="background: linear-gradient(to right, #00539c, #0077cc); color: white; text-align: left; padding: 8px; width: 15%; font-weight: 600;">
                <i class="fas fa-calendar-day" style="margin-right: 5px;"></i> Data/Hora
              </th>
              <th style="background: linear-gradient(to right, #00539c, #0077cc); color: white; text-align: left; padding: 8px; width: 12%; font-weight: 600;">
                <i class="fas fa-tachometer-alt" style="margin-right: 5px;"></i> Velocidade
              </th>
              <th style="background: linear-gradient(to right, #00539c, #0077cc); color: white; text-align: left; padding: 8px; width: 35%; font-weight: 600;">
                <i class="fas fa-sliders" style="margin-right: 5px;"></i> Atributos
              </th>
              <th style="background: linear-gradient(to right, #00539c, #0077cc); color: white; text-align: left; padding: 8px; width: 38%; font-weight: 600;">
                <i class="fas fa-map-marker-alt" style="margin-right: 5px;"></i> Localização
              </th>
            </tr>
          </thead>
          <tbody>
            ${getTableRows(routePoints.value)}
          </tbody>
        </table>
      </div>
      
      <!-- Rodapé moderno -->
      <div class="pdf-footer" style="margin-top: 20px; padding: 10px; border-top: 1px solid #eaeaea; display: flex; justify-content: space-between; align-items: center;">
        <div style="display: flex; align-items: center;">
          <img src="/img/favicon.ico" alt="Logo" style="height: 14px; margin-right: 6px;" />
          <span style="font-size: 10px; color: #666;">Tarkan - Sistema de Rastreamento</span>
        </div>
        <div style="display: flex; align-items: center; font-size: 10px; color: #666;">
          <i class="fas fa-file-pdf" style="margin-right: 5px;"></i> Página 1/1
        </div>
      </div>
    </div>
    `;
    
    // Criar elemento temporário e adicionar o HTML
    const tempElement = document.createElement('div');
    tempElement.innerHTML = htmlContent;
    document.body.appendChild(tempElement);
    
    // Configurações do html2pdf
    const pdfOptions = {
      margin: [10, 10, 15, 10], // [top, right, bottom, left]
      filename: `Relatório_${deviceInfo?.name || 'Dispositivo'}_${dayjs().format('YYYYMMDD')}.pdf`,
      image: { type: 'jpeg', quality: 0.98 },
      html2canvas: { 
        scale: 2, 
        useCORS: true,
        letterRendering: true,
        allowTaint: true
      },
      jsPDF: { 
        unit: 'mm', 
        format: 'a4', 
        orientation: 'portrait',
        compress: true
      }
    };
    
    // Gerar PDF
    await html2pdf().from(tempElement).set(pdfOptions).save();
    
    // Remover elemento temporário
    document.body.removeChild(tempElement);
    
    // Mensagem de sucesso
    ElMessage({
      message: 'PDF gerado com sucesso!',
      type: 'success',
      duration: 3000
    });
    
  } catch (error) {
    console.error("Erro ao gerar PDF:", error);
    ElMessage.error("Não foi possível gerar o PDF. Verifique o console para detalhes.");
  }
};

// Função auxiliar para gerar as linhas da tabela
function getTableRows(points) {
  // Limitar pontos para não sobrecarregar
  const maxPoints = 50;
  let pointsToShow;
  
  if (points.length > maxPoints) {
    // Mostrar metade do início e metade do final
    const half = Math.floor(maxPoints / 2);
    pointsToShow = [
      ...points.slice(0, half),
      ...points.slice(points.length - half)
    ];
  } else {
    pointsToShow = points;
  }
  
  return pointsToShow.map(point => {
    const datetime = dayjs(point.deviceTime).format('DD/MM HH:mm:ss');
    const speed = `${Math.round(point.speed * 1.852)} km/h`;
    const address = point.address || `${point.latitude.toFixed(5)}, ${point.longitude.toFixed(5)}`;
    
    // Formatar atributos dos pontos com ícones
    const attrs = [];
    
    if (point.attributes) {
      // Ignição
      if (point.attributes.ignition !== undefined) {
        const ignitionIcon = point.attributes.ignition ? 
          '<i class="fas fa-key" style="color: #2ecc71; margin-right: 3px;"></i>' : 
          '<i class="fas fa-key" style="color: #e74c3c; margin-right: 3px;"></i>';
        attrs.push(`<div style="margin-bottom: 4px; display: flex; align-items: center;">${ignitionIcon} <strong>Ignição:</strong> ${point.attributes.ignition ? 'Ligada' : 'Desligada'}</div>`);
      }
      
      // Movimento
      if (point.attributes.motion !== undefined) {
        const motionIcon = point.attributes.motion ? 
          '<i class="fas fa-car" style="color: #2ecc71; margin-right: 3px;"></i>' : 
          '<i class="fas fa-parking" style="color: #e74c3c; margin-right: 3px;"></i>';
        attrs.push(`<div style="margin-bottom: 4px; display: flex; align-items: center;">${motionIcon} <strong>Movimento:</strong> ${point.attributes.motion ? 'Em movimento' : 'Parado'}</div>`);
      }
      
      // Energia/Bateria
      if (point.attributes.power !== undefined) {
        const powerValue = parseFloat(point.attributes.power);
        const powerIcon = powerValue > 11 ? 
          '<i class="fas fa-car-battery" style="color: #2ecc71; margin-right: 3px;"></i>' : 
          '<i class="fas fa-car-battery" style="color: #e74c3c; margin-right: 3px;"></i>';
        attrs.push(`<div style="margin-bottom: 4px; display: flex; align-items: center;">${powerIcon} <strong>Bateria:</strong> ${powerValue.toFixed(1)}V</div>`);
      }
      
      // Bloqueio
      if (point.attributes.blocked !== undefined) {
        const blockIcon = point.attributes.blocked ? 
          '<i class="fas fa-lock" style="color: #e74c3c; margin-right: 3px;"></i>' : 
          '<i class="fas fa-unlock" style="color: #2ecc71; margin-right: 3px;"></i>';
        attrs.push(`<div style="margin-bottom: 4px; display: flex; align-items: center;">${blockIcon} <strong>Bloqueio:</strong> ${point.attributes.blocked ? 'Bloqueado' : 'Desbloqueado'}</div>`);
      }
      
      // Altitude
      if (point.attributes.altitude !== undefined) {
        attrs.push(`<div style="margin-bottom: 4px; display: flex; align-items: center;"><i class="fas fa-mountain" style="color: #00539c; margin-right: 3px;"></i> <strong>Altitude:</strong> ${point.attributes.altitude.toFixed(0)}m</div>`);
      }
      
      // Distância
      if (point.attributes.distance !== undefined) {
        const distValue = (point.attributes.distance / 1000).toFixed(2);
        attrs.push(`<div style="margin-bottom: 4px; display: flex; align-items: center;"><i class="fas fa-ruler" style="color: #00539c; margin-right: 3px;"></i> <strong>Dist.:</strong> ${distValue}km</div>`);
      }
      
      // Combustível 
      if (point.attributes.fuel !== undefined) {
        const fuelValue = parseFloat(point.attributes.fuel);
        const fuelIcon = fuelValue > 20 ? 
          '<i class="fas fa-gas-pump" style="color: #2ecc71; margin-right: 3px;"></i>' : 
          '<i class="fas fa-gas-pump" style="color: #e74c3c; margin-right: 3px;"></i>';
        attrs.push(`<div style="margin-bottom: 4px; display: flex; align-items: center;">${fuelIcon} <strong>Combustível:</strong> ${fuelValue.toFixed(1)}L</div>`);
      }
    }
    
    const attributesHTML = attrs.length > 0 ? 
      attrs.join('') : 
      '<div style="color: #999; display: flex; align-items: center;"><i class="fas fa-info-circle" style="margin-right: 3px;"></i> Sem atributos disponíveis</div>';
    
    // Alternância de cores de fundo com estilo mais suave
    const rowBg = pointsToShow.indexOf(point) % 2 === 0 ? 'background-color: #f8f9fa;' : 'background-color: #ffffff;';
    
    return `
      <tr style="${rowBg} transition: background-color 0.2s;">
        <td style="border: 1px solid #e9ecef; padding: 7px; font-size: 10px; vertical-align: top; border-left: 3px solid #00539c;">
          <div style="display: flex; align-items: center;">
            <i class="fas fa-clock" style="color: #00539c; margin-right: 5px; font-size: 9px;"></i>
            ${datetime}
          </div>
        </td>
        <td style="border: 1px solid #e9ecef; padding: 7px; font-size: 10px; vertical-align: top;">
          <div style="display: flex; align-items: center;">
            <i class="fas fa-tachometer-alt" style="color: ${parseInt(speed) > 0 ? '#e74c3c' : '#7f8c8d'}; margin-right: 5px; font-size: 9px;"></i>
            ${speed}
          </div>
        </td>
        <td style="border: 1px solid #e9ecef; padding: 7px; font-size: 10px; vertical-align: top;">${attributesHTML}</td>
        <td style="border: 1px solid #e9ecef; padding: 7px; font-size: 10px; vertical-align: top;">
          <div style="display: flex; align-items: flex-start;">
            <i class="fas fa-map-marker-alt" style="color: #00539c; margin-right: 5px; margin-top: 2px; font-size: 9px;"></i>
            <span>${address}</span>
          </div>
        </td>
      </tr>
    `;
  }).join('');
}

// Función para calcular duración de parada (en horas) - Comentada para evitar error de no utilización
/* 
function calculateStopDuration(routePoints) {
  let stopDuration = 0;

  routePoints.forEach((point, index) => {
    if (index === 0) return; // Ignorar el primer punto

    const prevPoint = routePoints[index - 1];
    const speed = point.speed || 0;          // Velocidad actual
    const prevSpeed = prevPoint.speed || 0;  // Velocidad del punto anterior
    const ignition = point.ignition;         // Estado de la ignición
    const prevIgnition = prevPoint.ignition;
    const motion = point.motion || false;    // Estado de movimiento (true o false)
    const prevMotion = prevPoint.motion || false;
    const valid = point.valid;               // Estado de validez (true o false)
    const prevValid = prevPoint.valid;

    // Combinamos los criterios para detectar una parada:
    // 1. La velocidad es cercana a 0 (parada).
    // 2. El motor está apagado (ignition es false).
    // 3. El vehículo no se está moviendo (motion es false).
    // 4. El estado general es válido (valid debe ser true).
    if (
      Math.abs(speed) < 0.1 &&              // Velocidad cercana a cero
      Math.abs(prevSpeed) < 0.1 &&          // Velocidad previa cercana a cero
      (ignition === false || prevIgnition === false) && // Motor apagado
      (motion === false || prevMotion === false) && // Vehículo detenido
      (valid === false || prevValid === false) // Vehículo no válido (detenido)
    ) {
      // Calculamos la diferencia de tiempo en minutos entre el punto actual y el anterior
      const diffInMinutes = dayjs(point.deviceTime).diff(dayjs(prevPoint.deviceTime), 'minute');
      stopDuration += diffInMinutes / 60;  // Convertimos la diferencia de minutos a horas
    }
  });

  return stopDuration;
}
*/

/* 
// Función para calcular tiempo de motor - Comentada para evitar error de no utilización
function calculateEngineTime(routePoints) {
  let engineTime = 0;

  // Primero verificamos si tenemos el campo hours en los puntos
  const firstPoint = routePoints[0];
  const lastPoint = routePoints[routePoints.length - 1];
  const firstPointHours = firstPoint?.attributes?.hours || 0;  // Hora del primer punto
  const lastPointHours = lastPoint?.attributes?.hours || 0;    // Hora del último punto

  // Si ambos primeros y últimos puntos tienen horas, las restamos
  if (firstPointHours && lastPointHours) {
    const hoursDifference = lastPointHours - firstPointHours;
    engineTime += hoursDifference;  // Sumamos la diferencia de horas entre el primer y último punto
  }

  // Ahora sumamos el tiempo de motor por cada punto con movimiento
  routePoints.forEach((point) => {
    const speed = point.speed || 0;
    if (speed > 0) {
      engineTime += 1; // Se cuenta como 1 hora de motor por cada punto con movimiento
    }
  });

  return engineTime;
}
*/

const fetchDeviceById = async (deviceId) => {
  try {
    const response = await fetch(`/api/devices/${deviceId}`); // Ajusta la URL según tu API
    if (!response.ok) {
      throw new Error(`Error: ${response.statusText}`);
    }
    const data = await response.json();
    return data; // Retorna el objeto completo del dispositivo
  } catch (error) {
    console.error("Error al obtener el dispositivo:", error);
    return null; // Retorna null en caso de error
  }
}

// Definir la variable isLoading como una referencia reactiva
const isLoading = ref(false);

// Função chamada ao clicar no botão de PDF
const handleGeneratePDF = () => {
  isLoading.value = true; // Ativamos o estado de carga
  
  // Mostrar mensagem para usuário
  ElMessage({
    message: 'Gerando PDF, aguarde o download iniciar...',
    type: 'info',
    duration: 5000
  });
  
  // Usar setTimeout para não bloquear a interface
  setTimeout(async () => {
    try {
      await generatePDF();
    } catch (error) {
      console.error('Erro ao gerar o PDF:', error);
      ElMessage.error('Falha ao gerar o PDF. Tente novamente.');
    } finally {
      isLoading.value = false;
    }
  }, 100);
};
</script>

<style scoped>
/* Estilos Gerais */
.history-card {
  margin-bottom: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  overflow: hidden;
}

.form-title {
  margin-top: 0;
  margin-bottom: 20px;
  color: var(--el-color-primary);
  font-size: 20px;
  font-weight: 600;
  display: flex;
  align-items: center;
}

.form-title i {
  margin-right: 10px;
}

/* Formulário */
.history-form {
  padding: 5px 0;
}

.form-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 8px;
}

.device-selector {
  flex: 1;
  margin-bottom: 0;
  margin-right: 10px;
}

.compact-buttons {
  white-space: nowrap;
}

.dates-row {
  align-items: center;
}

.date-container {
  display: flex;
  align-items: center;
  flex: 1;
  margin-right: 10px;
}

.date-container .el-input {
  flex: 1;
}

.date-separator {
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--el-text-color-secondary);
  margin: 0 5px;
}

.marker-container {
  display: flex;
  align-items: center;
  margin-left: 5px;
  min-width: 40px;
}

.marker-switch {
  display: flex;
}

.switch-label {
  display: flex;
  align-items: center;
  margin-left: 5px;
  font-size: 14px;
  color: var(--el-color-primary);
}

/* Conteúdo do Histórico */
.history-content {
  margin-top: 15px;
  border: 1px solid var(--el-border-color-light);
  border-radius: 8px;
  overflow: hidden;
}

/* Estados de carregamento e vazio */
.loading-state, .empty-state {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 60px 20px;
  color: var(--el-text-color-secondary);
  text-align: center;
}

.loading-state i, .empty-state i {
  font-size: 40px;
  margin-bottom: 15px;
  color: var(--el-color-primary);
}

/* Timeline */
.route-timeline {
  padding: 10px 0;
}

.timeline-point {
  display: flex;
  border-bottom: 1px dashed var(--el-border-color);
  position: relative;
}

.timeline-point:last-child {
  border-bottom: none;
}

.timeline-marker {
  position: relative;
  width: 120px;
  padding: 15px;
  text-align: right;
  color: var(--el-text-color-secondary);
  font-size: 12px;
}

.timeline-line {
  position: absolute;
  right: 5px;
  top: 0;
  bottom: 0;
  width: 2px;
  background-color: var(--el-color-primary-light-5);
}

.timeline-line-start {
  top: 50%;
}

.timeline-line-end {
  bottom: 50%;
}

.timeline-icon {
  position: absolute;
  right: -10px;
  top: 50%;
  transform: translateY(-50%);
  width: 30px;
  height: 30px;
  background-color: var(--el-color-primary);
  color: white;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  z-index: 2;
}

.timeline-dot {
  position: absolute;
  right: -4px;
  top: 50%;
  transform: translateY(-50%);
  width: 12px;
  height: 12px;
  background-color: var(--el-color-primary-light-5);
  border-radius: 50%;
  z-index: 2;
}

.timeline-date {
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
  line-height: 1.4;
}

.timeline-content {
  flex: 1;
  padding: 15px 20px;
  position: relative;
}

.address {
  font-size: 14px;
  margin-bottom: 10px;
  color: var(--el-text-color-primary);
  font-weight: 500;
}

.point-details {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}

.speed {
  font-size: 13px;
  color: var(--el-text-color-secondary);
  display: flex;
  align-items: center;
  gap: 5px;
}

.attributes {
  display: flex;
  gap: 10px;
}

.attribute {
  display: flex;
  align-items: center;
  gap: 4px;
  font-size: 13px;
  margin-left: 5px;
  position: relative;
}

.attribute:hover::after {
  content: attr(data-title);
  position: absolute;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  background: rgba(0, 0, 0, 0.7);
  color: white;
  padding: 2px 6px;
  border-radius: 4px;
  font-size: 10px;
  white-space: nowrap;
  z-index: 10;
}

.attribute-value {
  font-size: 11px;
  color: var(--el-text-color-secondary);
}

.location-link {
  color: var(--el-color-primary);
  text-decoration: none;
  transition: color 0.2s;
}

.location-link:hover {
  color: var(--el-color-primary-light-3);
  text-decoration: underline;
}

/* Utilitários */
.text-success {
  color: var(--el-color-success);
}

.text-danger {
  color: var(--el-color-danger);
}

.text-muted {
  color: var(--el-text-color-secondary);
}

/* Responsividade */
@media (max-width: 768px) {
  .form-row {
    flex-direction: column;
    align-items: stretch;
  }
  
  .device-selector {
    margin-right: 0;
    margin-bottom: 8px;
  }
  
  .compact-buttons {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 8px;
  }
  
  .date-container {
    margin-right: 0;
    margin-bottom: 8px;
  }
  
  .marker-switch {
    align-self: flex-end;
  }
  
  .timeline-marker {
    width: 80px;
    padding: 10px;
  }

  .timeline-date {
    right: 15px;
    font-size: 10px;
  }
}
</style>